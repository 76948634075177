import {
  get,
  isPlainObject,
  kebabCase,
  keys,
  map,
  merge,
  omit,
  reduce,
} from 'lodash';
import baselines, { BaselineMap, PartialBaselineMap } from './baselines';
import lists, { ListMap, ListName, PartialListMap } from './lists';
import typography, { FontMap, FontName, PartialFontMap } from './typography';
import varName from './varName';

import breakpoints, {
  BreakpointMap,
  BreakpointName,
  PartialBreakpointMap,
} from './breakpoints';

import containers, {
  ContainerMap,
  ContainerName,
  PartialContainerMap,
} from './containers';

import colors, {
  ColorMap,
  ColorName,
  ColorShade,
  PartialColorMap,
} from './colors';

export interface ThemeJSON {
  baselines?: PartialBaselineMap;
  breakpoints?: PartialBreakpointMap<string>;
  colors?: PartialColorMap;
  containers?: PartialContainerMap;
  fonts?: PartialFontMap;
  lists?: PartialListMap;
}

export type ThemeColorName = ColorName;
export type ThemeColorShade = ColorShade;

export default class Theme {
  private _readonly: boolean = true;
  private _baselines: BaselineMap;
  private _colors: ColorMap;
  private _breakpoints: BreakpointMap<string>;
  private _fonts: FontMap;
  private _containers: ContainerMap;
  private _lists: ListMap;

  static create(input: ThemeJSON = {}) {
    const t = new Theme();
    t._baselines = baselines(input.baselines);
    t._breakpoints = breakpoints(input.breakpoints);
    t._colors = colors(input.colors);
    t._containers = containers(input.containers);
    t._fonts = typography(input.fonts);
    t._lists = lists(input.lists);
    return t;
  }

  static fromJSON(input: any) {
    const t = new Theme();
    t._baselines = input.baselines;
    t._breakpoints = input.breakpoints;
    t._colors = input.colors;
    t._containers = input.containers;
    t._fonts = input.fonts;
    t._lists = input.lists;
    return t;
  }

  constructor(input: ThemeJSON = {}) {
    this._baselines = baselines(input.baselines);
    this._breakpoints = breakpoints(input.breakpoints);
    this._colors = colors(input.colors);
    this._containers = containers(input.containers);
    this._fonts = typography(input.fonts);
    this._lists = lists(input.lists);
  }

  baseline(breakpoint: BreakpointName) {
    return this._baselines[breakpoint];
  }

  color(color: ColorName, shade: ColorShade = '500'): string {
    return get(this._colors, [color, shade]) || '';
  }

  breakpoint(breakpoint: BreakpointName): string {
    return get(this._breakpoints, [breakpoint]) || '';
  }

  font<k extends="" FontName="">(font: K) {
    return reduce(
      keys(this._breakpoints) as BreakpointName[],
      (acc, breakpoint) => {
        const fnt = this._fonts[font];
        const fontSize = fnt.fontSize ? fnt.fontSize[breakpoint] : '';
        return {
          ...acc,
          [this.mq(breakpoint)]: { fontSize },
        };
      },
      omit(get(this._fonts, [font]) || {}, 'leading', 'fontSize') as object,
    );
  }

  container<k extends="" ContainerName="">(container: K): ContainerMap[K] {
    return get(this._containers, [container]) || {};
  }

  list<k extends="" ListName="">(list: K): ListMap[K] {
    return get(this._lists, [list]) || {};
  }

  mq<k extends="" BreakpointName="">(breakpoint: K) {
    return `@media (min-width: ${this._breakpoints[breakpoint]})`;
  }

  vr<k extends="" FontName="">(font: K) {
    return reduce(
      keys(this._breakpoints) as BreakpointName[],
      (acc, breakpoint) => {
        const fnt = this._fonts[font];
        const fontSize = fnt.fontSize ? fnt.fontSize[breakpoint] : '';
        const baseline = this.baseline(breakpoint);
        const fntLeading = fnt && fnt.leading && fnt.leading[breakpoint];
        const leading: number = fntLeading ? fntLeading : 1;
        if (this._readonly) {
          return {
            ...acc,
            [this.mq(breakpoint)]: {
              fontSize,
              lineHeight: baseline,
              marginTop: `${baseline * leading}rem`,
            },
          };
        }
        const lineHeightVar = varName(`baseline.${breakpoint}`);
        const marginVar = varName(`font.${font}.leading.${breakpoint}`);
        return {
          ...acc,
          [this.mq(breakpoint)]: {
            fontSize, // TODO: convert to var...
            lineHeight: lineHeightVar,
            marginTop: `calc(${lineHeightVar} * ${marginVar} * 1rem)`,
          },
        };
      },
      {},
    );
  }

  toJSON(): ThemeJSON {
    return {
      baselines: this._baselines,
      colors: this._colors,
      breakpoints: this._breakpoints,
      fonts: this._fonts,
      containers: this._containers,
      lists: this._lists,
    };
  }

  toCSSProperties(): string {
    const json = this.toJSON();
    const reduceToProps: any = (input: any, path: [] = []) =>
      reduce(
        input,
        (acc, v, k: any) => {
          if (isPlainObject(v)) {
            return [...acc, ...reduceToProps(v, path.concat(k))];
          } else {
            return [...acc, [[...path, k].join('-'), v]];
          }
        },
        [] as any,
      );
    const m = reduceToProps(json);
    return map(
      m,
      ([prop, value]: any) => `--${kebabCase(prop)}: ${value}`,
    ).join(';\n');
  }

  clone(): Theme {
    const t = new Theme();
    t._baselines = this._baselines;
    t._colors = this._colors;
    t._breakpoints = this._breakpoints;
    t._fonts = this._fonts;
    t._containers = this._containers;
    t._lists = this._lists;
    return t;
  }

  merge(input: ThemeJSON): Theme {
    return Theme.create(merge(this.toJSON(), input));
  }

  readonly(readonly: boolean = true) {
    const t = this.clone();
    t._readonly = readonly;
    return t;
  }
}
</k></k></k></k></k></string></string>