import { head, pick } from 'lodash';

import isNativeMouseEvent from './isNativeMouseEvent';
import isNativeTouchEvent from './isNativeTouchEvent';
import isSyntheticMouseEvent from './isSyntheticMouseEvent';
import isSyntheticTouchEvent from './isSyntheticTouchEvent';

export default (
  e: React.TouchEvent<any> | React.MouseEvent<any> | MouseEvent | TouchEvent,
): {
  clientX: number;
  clientY: number;
  pageX: number;
  pageY: number;
  screenX: number;
  screenY: number;
} => {
  let t;
  if (isSyntheticTouchEvent(e) || isNativeTouchEvent(e)) {
    t = head(e.touches);
  } else if (isSyntheticMouseEvent(e) || isNativeMouseEvent(e)) {
    t = e;
  }
  if (!t) {
    throw new Error('Could not extract client coordinates from event.');
  }
  return pick(t, 'clientX', 'clientY', 'pageX', 'pageY', 'screenX', 'screenY');
};
</any></any>