import {
  DEFAULT_ACTION_COLORS,
  DEFAULT_BACKGROUND_COLORS,
  DEFAULT_DIVIDER_COLOR,
  DEFAULT_TEXT_COLORS,
  DEFAULT_TONAL_OFFSET,
} from './constants';
import { last, sortBy } from 'lodash';
import Color from 'color';
import { IntentColorPalette } from './types';
import { ThemeInput } from './theme-input';
import { ThemeJson } from './theme-json';
import invariant from '@robotsnacks/invariant';
import { palettes } from './palettes';

/**
 * Input object to intent color palette factory.
 */
type MakeIntentColorPaletteInput = Partial<omit<intentcolorpalette, 'main'="">> & {
  main: IntentColorPalette['main'];
};

interface MakeIntentColorPaletteOptions {
  /**
   * Tonal offset to use when making light/dark variants.
   */
  tonalOffset: number;

  /**
   * Color options to use when picking contrasting colors. The highest
   * contrasting color will be selected.
   */
  contrastTextOptions?: string[];
}

const computeBestContrastColor = (
  color: string,
  contrastColors: string[],
): string => {
  invariant(
    contrastColors && contrastColors.length > 0,
    'Expected contrast color options.',
  );
  const clr = Color(color);
  return last(
    sortBy(contrastColors, (contrast): number => Color(contrast).contrast(clr)),
  ) as string;
};

/**
 * Computes the light variant of the passed main color.
 * @param color The base color.
 * @param tonalOffset Input tonal offset.
 */
const computeLightVariant = (color: Color, tonalOffset: number): string =>
  color.lighten(tonalOffset).toString();

/**
 * Computes the dark variant of the passed main color.
 * @param color The base color.
 * @param tonalOffset Input tonal offset.
 */
const computeDarkVariant = (color: Color, tonalOffset: number): string =>
  color.darken(tonalOffset * 1.5).toString();

/**
 * Constructs an intent color palette using the passed input.
 * @param input Input object describing color palette to construct.
 */
const makeIntentColorPalette = (
  input: MakeIntentColorPaletteInput | string,
  {
    contrastTextOptions = [palettes.common.black, palettes.common.white],
    tonalOffset,
  }: MakeIntentColorPaletteOptions,
): IntentColorPalette => {
  let contrastText: string | undefined;
  let dark: string | undefined;
  let light: string | undefined;
  let main: string;

  if (typeof input === 'string') {
    main = input;
  } else {
    ({ contrastText, dark, light, main } = input);
  }

  const color = Color(main);
  return {
    dark: dark || computeDarkVariant(color, tonalOffset),
    light: light || computeLightVariant(color, tonalOffset),
    main,
    contrastText:
      contrastText || computeBestContrastColor(main, contrastTextOptions),
  };
};

export class Palette {
  public static create(input: ThemeInput['palette'] = {}): Palette {
    const tonalOffset = input.tonalOffset || DEFAULT_TONAL_OFFSET;

    const error = makeIntentColorPalette(
      input.error || {
        dark: palettes.red[700],
        light: palettes.red[300],
        main: palettes.red[500],
      },
      { tonalOffset },
    );

    const primary = makeIntentColorPalette(
      input.primary || {
        dark: palettes.blue[700],
        light: palettes.blue[300],
        main: palettes.blue[500],
      },
      { tonalOffset },
    );

    const secondary = makeIntentColorPalette(
      input.secondary || {
        dark: palettes.indigo[700],
        light: palettes.indigo[300],
        main: palettes.indigo[500],
      },
      { tonalOffset },
    );

    const warning = makeIntentColorPalette(
      input.warning || {
        dark: palettes.deepOrange[700],
        light: palettes.deepOrange[300],
        main: palettes.deepOrange[500],
      },
      { tonalOffset },
    );

    return new Palette({
      action: { ...DEFAULT_ACTION_COLORS, ...input.action },
      background: { ...DEFAULT_BACKGROUND_COLORS, ...input.background },
      divider: input.divider || DEFAULT_DIVIDER_COLOR,
      error,
      primary,
      secondary,
      neutral: input.neutral || palettes.grey,
      text: { ...DEFAULT_TEXT_COLORS, ...input.text },
      tonalOffset,
      warning,
    });
  }

  public static toJSON(input?: ThemeInput['palette']): ThemeJson['palette'] {
    return Palette.create(input).toJSON();
  }

  public action: ThemeJson['palette']['action'];
  public background: ThemeJson['palette']['background'];
  public divider: ThemeJson['palette']['divider'];
  public error: ThemeJson['palette']['error'];
  public neutral: ThemeJson['palette']['neutral'];
  public primary: ThemeJson['palette']['primary'];
  public secondary: ThemeJson['palette']['secondary'];
  public text: ThemeJson['palette']['text'];
  public tonalOffset: ThemeJson['palette']['tonalOffset'];
  public warning: ThemeJson['palette']['warning'];

  public constructor(palette: ThemeJson['palette']) {
    this.action = palette.action;
    this.background = palette.background;
    this.divider = palette.divider;
    this.error = palette.error;
    this.neutral = palette.neutral;
    this.primary = palette.primary;
    this.secondary = palette.secondary;
    this.text = palette.text;
    this.tonalOffset = palette.tonalOffset;
    this.warning = palette.warning;
  }

  public toJSON(): ThemeJson['palette'] {
    return {
      action: this.action,
      background: this.background,
      divider: this.divider,
      error: this.error,
      neutral: this.neutral,
      primary: this.primary,
      secondary: this.secondary,
      text: this.text,
      tonalOffset: this.tonalOffset,
      warning: this.warning,
    };
  }
}
</omit<intentcolorpalette,>