import { kebabCase } from 'lodash';
import { cx, reflow } from '../utils';

import Transition, { TransitionProps, TransitionState } from '../Transition';

import React, {
  Component,
  ReactNode,
  TransitionEvent,
  TransitionEventHandler,
} from 'react';

export type CSSTransitionChildProps = {
  className?: string;
  domRef: (el: HTMLElement | null) => void;
  onTransitionEnd: TransitionEventHandler;
};

export interface CSSTransitionProps
  extends Pick<
    TransitionProps,
    Exclude<keyof TransitionProps,="" 'children'="" |="" 'timeout'="">
  > {
  children: (props: CSSTransitionChildProps) => ReactNode;
  className?: string;
  transitionClassNames: string | Partial<{ [key in TransitionState]: string }>;
}

export default class CSSTransition extends Component<csstransitionprops> {
  private _domRef: HTMLElement | null = null;

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <transition timeout="{false}" {...rest}="" onTransition="{this._reflow}">
        {(transitionState, endTransition) => {
          const handleTransitionEnd = (e: TransitionEvent) => {
            if (e.target === this._domRef) endTransition();
          };
          const props = {
            className: cx(this._getClassName(transitionState), className),
            onTransitionEnd: handleTransitionEnd,
            domRef: this._setDOMRef,
          };
          return children(props);
        }}
      </transition>
    );
  }

  public getDOMNode() {
    return this._domRef;
  }

  private _setDOMRef = (el: HTMLElement | null) => {
    this._domRef = el;
  };

  private _getClassName = (
    transitionState: TransitionState,
  ): string | undefined => {
    const { transitionClassNames } = this.props;
    if (typeof transitionClassNames === 'string') {
      return cx(
        transitionClassNames,
        transitionClassNames
          .split(' ')
          .map(c => `${c}--${kebabCase(transitionState)}`),
      );
    } else if (typeof transitionClassNames === 'object') {
      return transitionClassNames[transitionState];
    }
    return undefined;
  };

  private _reflow = () => {
    const { onTransition } = this.props;
    reflow(this._domRef);
    if (onTransition) onTransition();
  };
}
</csstransitionprops></keyof>