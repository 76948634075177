import { clamp, filter, omit, over } from 'lodash';

import React, {
  CSSProperties,
  Component,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

export type MagnetAnchor =
  | 'east-bottom'
  | 'east-north-east'
  | 'east-south-east'
  | 'east-top'
  | 'east'
  | 'north-east'
  | 'north-east-inside'
  | 'north-inside'
  | 'north-left'
  | 'north-north-east'
  | 'north-north-west'
  | 'north-right'
  | 'north-west'
  | 'north'
  | 'south-east'
  | 'south-left'
  | 'south-right'
  | 'south-south-east'
  | 'south-south-west'
  | 'south-west'
  | 'south'
  | 'west-bottom'
  | 'west-north-west'
  | 'west-south-west'
  | 'west-top'
  | 'west';

interface MagnetChildProps {
  anchor: MagnetAnchor;
  left: number;
  node: HTMLDivElement | null;
  top: number;
}

export interface MagnetProps extends HTMLAttributes<htmldivelement> {
  children?:
    | ReactElement<magnetchildprops>
    | ((props: MagnetChildProps) => ReactNode);
  domRef?: (el: HTMLDivElement | null) => void;
  anchor?: MagnetAnchor;
  padding?: number;
  slide?: boolean;
  swap?: boolean;
}

type Props = MagnetProps & typeof defaultProps;

type State = {
  anchor?: MagnetAnchor;
  left?: number;
  rect?: DOMRect | ClientRect;
  top?: number;
};

const defaultProps = Object.freeze({
  anchor: 'north' as MagnetAnchor,
});

const getInitialState = (props: Props): State => ({
  anchor: props.anchor,
  left: 0,
  top: 0,
});

const getOffsetParent = (target: HTMLElement) => {
  const offsetParent = target.offsetParent;
  if (!offsetParent) {
    throw new Error(`<magnet></magnet> must have an offset parent.`);
  }
  return offsetParent;
};

const calculateOptimalLeft = (
  anchor: MagnetAnchor,
  targetWidth: number,
  parentWidth: number,
  padding: number,
): number => {
  switch (anchor) {
    case 'north':
    case 'south':
    case 'north-inside':
      return (parentWidth - targetWidth) / 2;

    case 'north-west':
    case 'south-west':
    case 'west':
    case 'west-north-west':
    case 'west-south-west':
    case 'west-top':
    case 'west-bottom':
      return -targetWidth;

    case 'north-north-west':
    case 'south-south-west':
      return -targetWidth + padding;

    case 'east':
    case 'north-east':
    case 'east-north-east':
    case 'east-south-east':
    case 'south-east':
    case 'east-top':
    case 'east-bottom':
      return parentWidth;

    case 'south-south-east':
    case 'north-north-east':
      return parentWidth - padding;

    case 'north-left':
    case 'south-left':
      return 0;

    case 'north-right':
    case 'south-right':
    case 'north-east-inside':
      return parentWidth - targetWidth;
  }
};

const calculateOptimalTop = (
  anchor: MagnetAnchor,
  targetHeight: number,
  parentHeight: number,
  padding: number,
): number => {
  switch (anchor) {
    case 'north-east':
    case 'north-north-east':
    case 'north-north-west':
    case 'north-west':
    case 'north':
    case 'north-left':
    case 'north-right':
      return -targetHeight;

    case 'east-north-east':
    case 'west-north-west':
      return -targetHeight + padding;

    case 'south-east':
    case 'south-south-east':
    case 'south-south-west':
    case 'south-west':
    case 'south':
    case 'south-left':
    case 'south-right':
      return parentHeight;

    case 'east-south-east':
    case 'west-south-west':
      return parentHeight - padding;

    case 'west':
    case 'east':
      return (parentHeight - targetHeight) / 2;

    case 'west-top':
    case 'east-top':
    case 'north-inside':
    case 'north-east-inside':
      return 0;

    case 'west-bottom':
    case 'east-bottom':
      return parentHeight - targetHeight;
  }
};

/* tslint:disable cyclomatic-complexity */
const calculateNextState = (
  props: Props,
  target: HTMLElement,
  anchor: MagnetAnchor = props.anchor,
): State => {
  const { innerHeight, innerWidth } = window;
  const { padding = 0 } = props;

  const parent = getOffsetParent(target);
  const targetRect = target.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  const targetWidth = targetRect.width;
  const parentWidth = parentRect.width;
  const targetHeight = targetRect.height;
  const parentHeight = parentRect.height;

  const originLeft = parentRect.left;
  const originTop = parentRect.top;
  const windowLeft = -originLeft;
  const windowRight = innerWidth - originLeft;
  const windowTop = -originTop;
  const windowBottom = innerHeight - originTop;

  const optimalLeft = calculateOptimalLeft(
    anchor,
    targetWidth,
    parentWidth,
    padding,
  );

  const optimalTop = calculateOptimalTop(
    anchor,
    targetHeight,
    parentHeight,
    padding,
  );

  const left = clamp(
    optimalLeft,
    Math.min(windowLeft, parentWidth - padding),
    Math.max(windowRight - targetWidth, -targetWidth + padding),
  );

  const top = clamp(
    optimalTop,
    Math.min(windowTop, parentHeight - padding),
    Math.max(windowBottom - targetHeight, -targetHeight + padding),
  );

  if (props.swap && anchor === props.anchor) {
    const bottom = top + originTop + targetHeight;
    const right = left + originLeft + targetWidth;
    let swap: MagnetAnchor | null = null;
    if (-top < targetHeight) swap = swapNorthToSouth(anchor);
    if (!swap && bottom >= innerHeight) swap = swapSouthToNorth(anchor);
    if (!swap && -left < targetWidth) swap = swapWestToEast(anchor);
    if (!swap && right >= innerWidth) swap = swapEastToWest(anchor);
    if (swap) return calculateNextState(props, target, swap);
  }

  return {
    anchor,
    left: Math.round(props.slide ? left : optimalLeft),
    top: Math.round(props.slide ? top : optimalTop),
  };
};
/* tslint:enable cyclomatic-complexity */

const swapNorthToSouth = (anchor: MagnetAnchor): MagnetAnchor | null => {
  switch (anchor) {
    case 'north-left':
      return 'south-left';
    case 'north-north-east':
      return 'south-south-east';
    case 'north-north-west':
      return 'south-south-west';
    case 'north-right':
      return 'south-right';
    case 'north':
      return 'south';
    case 'north-west':
    case 'north-east':
    default:
      return null;
  }
};

const swapSouthToNorth = (anchor: MagnetAnchor): MagnetAnchor | null => {
  switch (anchor) {
    case 'south-left':
      return 'north-left';
    case 'south-right':
      return 'north-right';
    case 'south-south-east':
      return 'north-north-east';
    case 'south-south-west':
      return 'north-north-west';
    case 'south':
      return 'north';
    case 'south-west':
    case 'south-east':
    default:
      return null;
  }
};

const swapEastToWest = (anchor: MagnetAnchor): MagnetAnchor | null => {
  switch (anchor) {
    case 'east-bottom':
      return 'west-bottom';
    case 'east-north-east':
      return 'west-north-west';
    case 'east-south-east':
      return 'west-south-west';
    case 'east-top':
      return 'west-top';
    case 'east':
      return 'west';
    default:
      return null;
  }
};

const swapWestToEast = (anchor: MagnetAnchor): MagnetAnchor | null => {
  switch (anchor) {
    case 'west-bottom':
      return 'east-bottom';
    case 'west-north-west':
      return 'east-north-east';
    case 'west-south-west':
      return 'east-south-east';
    case 'west-top':
      return 'east-top';
    case 'west':
      return 'east';
    default:
      return null;
  }
};

const calculateStyle = (state: State): CSSProperties => ({
  position: 'absolute',
  left: `${state.left || 0}px`,
  top: `${state.top || 0}px`,
});

export default class Magnet extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  private _domRef: HTMLDivElement | null = null;

  componentDidMount() {
    this._measure(() => this._measure(() => this.forceUpdate()));
    this._addEventListeners();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.anchor !== prevProps.anchor) {
      this._measure(() => this._measure(() => this.forceUpdate()));
    }
  }

  componentWillUnmount() {
    this._removeEventListeners();
  }

  render() {
    const { anchor, style, className, domRef, ...rest } = this.props;

    return (
      <div {...omit(rest,="" 'anchor',="" 'clamp',="" 'slide',="" 'swap',="" 'padding')}="" className="{className}" style="{{" ...style,="" ...calculateStyle(this.state),="" }}="" ref="{over(filter([this._setDOMRef," domRef])="" as="" any)}="">
        {this._renderChildren()}
      </div>
    );
  }

  getDOMNode(): HTMLDivElement | null {
    return this._domRef;
  }

  private _setDOMRef = (el: HTMLDivElement | null) => {
    this._domRef = el;
  };

  private _renderChildren = (): ReactNode => {
    const { children } = this.props;
    const props = {
      anchor: this.state.anchor as MagnetAnchor,
      left: this.state.left || 0,
      node: this._domRef,
      top: this.state.top || 0,
    };
    if (typeof children === 'function') {
      return children(props);
    } else if (isValidElement(children)) {
      // FIXME: Since TS 3.2, it cannot determine type of children.
      return cloneElement(children as any, props);
    }
    throw new Error('<magnet></magnet> accepts ReactElement or function.');
  };

  private _measure = (cb?: () => void): void => {
    if (this._domRef) {
      this.setState(calculateNextState(this.props, this._domRef), cb);
    }
  };

  private _handleEvent = () => this._measure();

  private _addEventListeners = () => {
    window.addEventListener('resize', this._handleEvent);
    window.addEventListener('scroll', this._handleEvent);
    window.addEventListener('taffy:drag', this._handleEvent);
  };

  private _removeEventListeners = () => {
    window.removeEventListener('resize', this._handleEvent);
    window.removeEventListener('scroll', this._handleEvent);
    window.removeEventListener('taffy:drag', this._handleEvent);
  };
}
</props,></magnetchildprops></htmldivelement>