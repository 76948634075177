import { noop } from 'lodash';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

import React, { HTMLAttributes, PureComponent, createRef } from 'react';

const styles = createStyles<'root'>(() => ({
  root: {
    height: 'auto',
    width: '100%',
  },
}));

export interface ImagePreviewProps extends HTMLAttributes<htmlcanvaselement> {
  height: number;
  width: number;
  file: File;
}

type Props = ImagePreviewProps &
  WithStyles<imagepreviewprops, typeof="" styles=""> &
  typeof defaultProps;

const defaultProps = Object.freeze({
  onLoad: noop,
});

const cropImage = (
  props: Props,
  img: HTMLImageElement,
): [number, number, number, number, number, number, number, number] => {
  const dHeight = props.height;
  const dWidth = props.width;
  const sHeight = img.height;
  const sWidth = img.width;
  const dAspectRatio = dWidth / dHeight;
  const sAspectRatio = sWidth / sHeight;
  const dh = dHeight / sHeight;
  const dw = dWidth / sWidth;
  if (dAspectRatio >= sAspectRatio) {
    // The destination is equal or "wider" than the source, in which case we
    // crop the top and bottom of the source image.
    const r = dHeight / dw;
    return [0, (sHeight - r) / 2, sWidth, r, 0, 0, dWidth, dHeight];
  } else {
    // The source is "wider" than the destination, in which case we crop the
    // left and right of the source image.
    const r = dWidth / dh;
    return [(sWidth - r) / 2, 0, r, sHeight, 0, 0, dWidth, dHeight];
  }
};

class ImagePreview extends PureComponent<props> {
  static defaultProps = defaultProps;
  private _canvasRef = createRef<htmlcanvaselement>();

  public componentDidMount() {
    this._readImage();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.file !== this.props.file) {
      this._readImage();
    }
  }

  public render() {
    const { classes, className, file, theme, ...rest } = this.props;
    return (
      <canvas {...rest}="" ref="{this._canvasRef}" className="{cx(classes.root," className)}=""></canvas>
    );
  }

  private _readImage() {
    const { file } = this.props;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = this._handleLoad;
    }
  }

  private _handleLoad = (reader: ProgressEvent) => {
    const { current: canvas } = this._canvasRef;
    const result = (reader.target as FileReader).result as string;
    if (canvas) {
      const img = document.createElement('img');
      img.src = result;
      img.onload = () => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, this.props.width, this.props.height);
          ctx.filter = 'blur(30px)';
          ctx.drawImage(img, ...cropImage(this.props, img));
          this.props.onLoad();
        }
      };
    }
  };
}

export default withStyles(styles)(ImagePreview);
</htmlcanvaselement></props></imagepreviewprops,></htmlcanvaselement>