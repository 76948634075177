import CSS from 'csstype';
import { reduce } from 'lodash';

export type ContainerName = 'narrow' | 'prose' | 'wide' | 'splash';

export type Container = Pick<css.properties, 'padding'="" |="" 'maxWidth'="">;

export type ContainerMap = { [K in ContainerName]: Container };

export type PartialContainerMap = Partial<containermap>;

const defaults = {
  narrow: {
    maxWidth: '40rem',
    padding: '1rem',
  },
  prose: {
    maxWidth: '65rem',
    padding: '1rem',
  },
  wide: {
    maxWidth: '85rem',
    padding: '1rem',
  },
  splash: {
    maxWidth: '100%',
    padding: '0',
  },
};

export default (input: PartialContainerMap = {}) =>
  reduce(
    ['narrow', 'prose', 'wide', 'splash'] as ContainerName[],
    (acc, c) => ({
      ...acc,
      [c]: {
        ...defaults[c],
        ...input[c],
      },
    }),
    {} as ContainerMap,
  );
</containermap></css.properties,>