import { Theme, TypographyColor } from '../theme';
import { Interpolator } from './interpolator';
import { assertTheme } from '../util';

/**
 * Maps a typography color name to a palette color.
 * @param theme Mint theme instance.
 * @param color The typography color.
 */
const mapColorToPalette = (theme: Theme, color?: TypographyColor): string => {
  switch (color) {
    case 'initial':
      return '';
    case 'inherit':
      return 'inherit';
    case 'error':
      return theme.palette.error.main;
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'text.disabled':
      return theme.palette.text.disabled;
    case 'text.hint':
      return theme.palette.text.hint;
    case 'text.secondary':
      return theme.palette.text.secondary;
    case 'warning':
      return theme.palette.warning.main;
    case 'text.primary':
    default:
      return theme.palette.text.primary;
  }
};

/**
 * Maps a typography color name to a palette custom property name.
 * @param theme Mint theme instance.
 * @param color The typography color.
 */
const mapColorToCustomProperty = (
  theme: Theme,
  color?: TypographyColor,
): string => {
  switch (color) {
    case 'initial':
      return '';
    case 'inherit':
      return 'inherit';
    case 'error':
      return theme.vars.palette.error.main;
    case 'primary':
      return theme.vars.palette.primary.main;
    case 'secondary':
      return theme.vars.palette.secondary.main;
    case 'text.disabled':
      return theme.vars.palette.text.disabled;
    case 'text.hint':
      return theme.vars.palette.text.hint;
    case 'text.secondary':
      return theme.vars.palette.text.secondary;
    case 'warning':
      return theme.vars.palette.warning.main;
    case 'text.primary':
    default:
      return theme.vars.palette.text.primary;
  }
};

/**
 * Interpolates font colors.
 * @param color The color or color name factory.
 */
export const interpolateTextColor = <tprops extends="" {="" theme:="" Theme="" }="">(
  color: TypographyColor | ((props: TProps) => TypographyColor | undefined),
): Interpolator => (props): string => {
  const theme = assertTheme(props);
  const resolvedColor =
    typeof color === 'function' ? color(props as TProps) : color;
  const typographyColor = mapColorToPalette(theme, resolvedColor);
  const customProp = mapColorToCustomProperty(theme, resolvedColor);

  // If there's no color to map to (i.e. color is "initial") just return an
  // empty string.
  if (!typographyColor) {
    return '';
  }

  return `
    color: ${typographyColor};
    color: var(${customProp}, ${typographyColor});
  `;
};
</tprops>